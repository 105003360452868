import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import Newsletter from "../components/newsletter"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Page = ({ data: { page } }) => {
  const { title, content, uri, databaseId, smartcrawlSeo } = page
  const featuredImage = {
    fluid: page.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: page.featuredImage?.node?.altText || ``,
  }

  return (
    <Layout>
      <main id="main" className="site-main dft-padding readability" role="main">
        <article
          id={"post-" + databaseId}
          className="page type-page status-publish format-standard hentry page-template-default"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Seo
            title={smartcrawlSeo?.title}
            description={smartcrawlSeo?.metaDesc}
            ogTitle={smartcrawlSeo?.opengraphTitle}
            ogDescription={smartcrawlSeo?.opengraphDescription}
            twTitle={smartcrawlSeo?.twitterTitle}
            twDescription={smartcrawlSeo?.twitterDescription}
            featuredImage={page.featuredImage?.node?.mediaItemUrl}
            ogImage={smartcrawlSeo?.opengraphImage?.mediaItemUrl}
            twImage={smartcrawlSeo?.twitterImage?.mediaItemUrl}
          />

          <Helmet>
            <script type="application/ld+json">
              {`
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "${page.title}",
        "@id": "https://pointofinquiry.org/#website",
        "url": "https://pointofinquiry.org/",
        "isAccessibleForFree": true,
        "image": {
              "@type": "ImageObject",
                "url": "https://cdn.centerforinquiry.org/wp-content/uploads/sites/30/2019/01/22164519/Point-of-Inquiry-Logo-2018.jpg"
  },
        "sourceOrganization": 
          {
            "@type": "Organization",
            "@id": "https://centerforinquiry.org/#organization",
            "name": " Center for Inquiry" 
                    }
              }     
          `}
            </script>
          </Helmet>

          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              className="article-featured-image d-print-none"
              alt={featuredImage.alt}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          )}

          <div className="readability">
            <header className="entry-header">
              <h1 className="entry-title" itemProp="headline">
                {parse(title)}
              </h1>
              <hr />
            </header>

            {!!content && (
              <section itemProp="articleBody">{parse(content)}</section>
            )}
            {title === "Subscribe" && <Newsletter />}
          </div>

          <footer></footer>
        </article>
      </main>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      databaseId
      uri
      slug

      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          mediaItemUrl
        }
      }
    }
  }
`
