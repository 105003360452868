import React, { useState } from "react"

const Newsletter = () => {
  const defaultValues = {
    email: ``,
    lists: [
      {
        id: 1,
        value: 1,
      },
    ],
  }
  const [values, setValues] = useState(defaultValues)
  const [newsletterStatus, setNewsletterStatus] = useState(false)
  const handleSubmit = event => {
    fetch(
      "https://centerforinquiry.org/pointofinquiry/wp-json/newsletter/v2/subscriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
    console.log(values)
    setValues(defaultValues)
    setTimeout(function () {
      setNewsletterStatus(`success`)
    }, 1500)
  }

  const handleChange = event => {
    event.persist()
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  const NewsletterStatusFeedback = () => {
    switch (newsletterStatus) {
      case `success`:
        return (
          <p className="feedback success h5 mt-3">
            <span role="img" aria-label="Success">
              ✅
            </span>{" "}
            Thank you for subscribing!
          </p>
        )
      case `loading`:
        return (
          <p className="feedback info h5 mt-3">
            <span role="img" aria-label="Loading">
              ⌛
            </span>{" "}
            Please wait...
          </p>
        )
      case `error`:
        return (
          <p className="feedback error h5 mt-3">
            There was an error with your submission. Please contact support or
            try again later.
          </p>
        )
      default:
        return ""
    }
  }

  return (
    <div className="tnp tnp-subscription">
      <form
        id="newsletter-form"
        onSubmit={event => {
          event.preventDefault()
          setNewsletterStatus(`loading`)
          handleSubmit()
        }}
      >
        {!!newsletterStatus ? (
          <NewsletterStatusFeedback />
        ) : (
          <div className="tnp-field tnp-field-email">
            <input
              className="tnp-email"
              type="email"
              name="email"
              placeholder="username@example.com"
              id="email-field"
              value={values.email}
              onChange={handleChange}
              required
              disabled={newsletterStatus === `loading`}
            />
            <div className="wp-block-buttons is-content-justification-center mt-3">
              <div className="tnp-field tnp-field-button wp-block-button">
                <input
                  className="wp-block-button__link has-white-color has-poi-gold-background-color has-text-color has-background"
                  type="submit"
                  value="Subscribe"
                  id="submit-button"
                  disabled={newsletterStatus === `loading`}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default Newsletter

/*handleSubmit = (event) => {
    event.preventDefault()
    fetch("https://api.staticman.net/...", {
      method: "POST",
      body: queryString.stringify({
        this.state.username,
        this.state.subject
      })
    })
  }

  const handleChange = event => {
    event.persist()
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
    console.log(values)
  }
const Newsletter = () => {
  const [blogs, setBlogs] = useState([])

  useEffect(() => {
    fetch("https://centerforinquiry.org/pointofinquiry/wp-json/newsletter/v2/subscriptions")
      .then(resp => resp.json())
      .then(data => setBlogs(data))
  })

  return (
    <>
      <div className="tnp tnp-subscription">
        <form
          method="post"
          action="https://centerforinquiry.org/pointofinquiry/?na=s"
          onsubmit="return newsletter_check(this)"
        >
          <div className="tnp-field tnp-field-email">
            <input
              className="tnp-email"
              type="email"
              name="ne"
              placeholder="username@example.com"
              required=""
            />
          </div>
          <div className="tnp-lists">
            <div className="d-none tnp-field tnp-field-list">
              <label>
                <input
                  className="tnp-preference"
                  checked="checked"
                  type="checkbox"
                  name="nl[]"
                  value="1"
                />
                {
  "email": "user@example.com",
  "lists": [
    {
      "id": 0,
      "value": 0
    }
  ],
}
                &nbsp;Point of Inquiry
              </label>
            </div>
          </div>
          <div className="wp-block-buttons is-content-justification-center mt-3">
            <div className="tnp-field tnp-field-button wp-block-button">
              <input
                className="wp-block-button__link has-poi-blue-color has-poi-gold-background-color has-text-color has-background"
                type="submit"
                value="Subscribe"
              />
            </div>
          </div>
        </form>
      </div>
      {blogs.map(blog => (
        <SingleBlog
          key={blog.id}
          image={
            blog["_embedded"]["wp:featuredmedia"][0]["media_details"]["sizes"][
              "full"
            ]["source_url"]
          }
          headerLink={blog.link}
          date={makeDate(blog.date)}
          desc={blog.excerpt.rendered}
          title={blog.title.rendered}
        />
      ))}
    </>
  )
}

export default Newsletter*/
